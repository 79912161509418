import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { FiAlertTriangle, FiFilter } from 'react-icons/fi';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { lighten, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
  },
  closeButton: {
    position: 'absolute',
  },
}));

const newItemPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  handleFunction: PropTypes.func.isRequired,
});

export default function TableListToolbar({
  newItem,
  searchRef,
  handleChangeSearchText,
  handleListErrorButton,
  method,
  canEdit,
}) {
  const classes = useToolbarStyles();
  const [actionName, setActionName] = useState('Listando pedidos com erro');

  useEffect(() => {
    if (method)
      setActionName(
        method === 'index'
          ? 'Listando pedidos atualizados'
          : 'Listando pedidos com erro'
      );
  }, [method]);

  const handleChangeText = event => {
    handleChangeSearchText(event.target.value);
  };

  return (
    <AppBar
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItem: 'center',
        justifyContent: 'space-between',
        position: 'relative',
        padding: '10px',
        background: '#fff',
        paddingLeft: '50px',
        paddingRight: '50px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <TextField
          label="Buscar..."
          variant="outlined"
          inputRef={searchRef}
          size="small"
          type="search"
          onChange={handleChangeText}
          style={{ flex: 1 }}
        />
        <Tooltip title="Filtrar">
          <IconButton
            onClick={() => {
              // setMethod('index');
              handleChangeSearchText(
                searchRef.current && searchRef.current.value
              );
            }}
          >
            <FiFilter />
          </IconButton>
        </Tooltip>
        {method && (
          <Tooltip title="Listar com ERRO">
            <IconButton
              onClick={() => {
                // setMethod('indexError');
                handleListErrorButton(
                  searchRef.current && searchRef.current.value
                );
              }}
            >
              <FiAlertTriangle />
            </IconButton>
          </Tooltip>
        )}
        {method && (
          <Typography size="small" style={{ flex: 1, color: '#000' }}>
            {actionName}
          </Typography>
        )}
      </div>

      <Typography className={classes.title} variant="h6" component="div">
        {newItem && (
          <Button
            style={{
              marginLeft: 10,
              paddingLeft: 30,
              paddingRight: 30,
            }}
            variant="contained"
            color="primary"
            onClick={newItem.handleFunction}
            disabled={!canEdit}
          >
            {newItem.label}
          </Button>
        )}
      </Typography>
    </AppBar>
  );
}

TableListToolbar.propTypes = {
  newItem: newItemPropType,
  searchRef: PropTypes.shape().isRequired,
  handleChangeSearchText: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
};

TableListToolbar.defaultProps = {
  newItem: null,
  canEdit: true,
};
