import { useRef } from 'react';

import PropTypes from 'prop-types';
import {
  DatePicker,
  Form,
  Grid,
  Loading,
} from '~/components/Form';
import { useOrder } from '~/providers/orders';
import { validate } from '~/validators/reprocess';

import {
  Button,
  Dialog,
  DialogTitle,
} from './styles';

export default function ReprocessDialog({
  setOrder,
  showDialog,
  setShowDialog,
  setMethod,
  setPage,
  method,
}) {
  const formRef = useRef(null);
  const { reprocess, orderLoading } = useOrder();

  // const [actionName, setActionName] = useState('Listando pedidos com erro');

  const handleClose = () => {
    setShowDialog(false);
  };

  async function handleSubmit(data) {
    const { errorMessages, ...parsedData } = await validate(data);

    if (setMethod) setMethod('index');
    if (setPage) setPage(0);
    if (errorMessages && formRef.current)
      formRef.current.setErrors(errorMessages);
    else {
      handleClose();

      await reprocess(parsedData);

      if (setPage) setPage(0);
      if (setOrder) setOrder('desc');
    }
  }

  return (
    <Dialog onClose={handleClose} open={showDialog}>
      <DialogTitle>Reprocessar Pedidos</DialogTitle>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Grid>
          <DatePicker required name="start_at" label="Data de Início" />
          <DatePicker required name="end_at" label="Data de Fim" />

          <Button
            disabled={orderLoading}
            type="submit"
            variant="contained"
            color="primary"
          >
            Confirmar
            {orderLoading && <Loading color="secondary" />}
          </Button>
        </Grid>
      </Form>
    </Dialog>
  );
}

ReprocessDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  setShowDialog: PropTypes.func.isRequired,
};
