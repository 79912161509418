import { createContext, useCallback, useContext, useState } from 'react';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useErrorHandler } from '../errorHandler';
import * as actions from './actions';

const INITIAL_STATE = {
  orders: [],
  order: {},
  orderErrors: {},
  orderLoading: false,
  orderListLoading: false,
};

const OrderContext = createContext(INITIAL_STATE);

export function OrderProvider({ children }) {
  const { setErrorHandlerData } = useErrorHandler();
  const history = useHistory();

  const [data, setData] = useState(INITIAL_STATE);

  const setOrderData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const index = useCallback(
    async ({ search = '', order_by = '', order = '' }) => {
      setOrderData({ orderListLoading: true });

      const orderData = await actions.index({
        search,
        order_by,
        order,
      });

      if (orderData.orderErrors)
        setErrorHandlerData({
          error: {
            ...orderData.orderErrors,
            resolveFunction: () => index({ search, order_by, order }),
          },
        });

      setOrderData({
        ...orderData,
        orderListLoading: false,
      });
    },
    [setOrderData, setErrorHandlerData]
  );

  const indexError = useCallback(
    async ({ search = '', order_by = '', order = '' }) => {
      setOrderData({ orderListLoading: true });

      const orderData = await actions.indexError({
        search,
        order_by,
        order,
      });

      if (orderData.orderErrors)
        setErrorHandlerData({
          error: {
            ...orderData.orderErrors,
            resolveFunction: () => indexError({ search, order_by, order }),
          },
        });

      setOrderData({
        ...orderData,
        orderListLoading: false,
      });
    },
    [setOrderData, setErrorHandlerData]
  );

  const show = useCallback(
    async ({ orderUuid = '' }) => {
      setOrderData({ orderLoading: true });

      const orderData = await actions.show({ orderUuid });

      if (orderData.orderErrors) {
        setErrorHandlerData({
          error: {
            ...orderData.orderErrors,
            resolveFunction: () => show({ orderUuid }),
          },
        });

        history.goBack();
      }

      setOrderData({
        ...orderData,
        orderLoading: false,
      });
    },
    [setOrderData, setErrorHandlerData, history]
  );

  const destroy = useCallback(
    async ({ order = {} }) => {
      setOrderData({ orderLoading: true });

      const orderData = await actions.destroy({ order });

      if (orderData.orderErrors) {
        setErrorHandlerData({
          error: {
            ...orderData.orderErrors,
            resolveFunction: () => destroy({ order }),
          },
        });

        setOrderData({
          ...orderData,
          orderLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          orders: [
            ...oldData.orders.filter(
              auxOrders => auxOrders.uuid !== order.uuid
            ),
          ],
          orderLoading: false,
        }));

        toast.success('Integração removida com sucesso!');
      }
    },
    [setOrderData, setErrorHandlerData]
  );

  const reprocess = useCallback(
    async ({ start_at = '', end_at = '', order = '' }) => {
      setOrderData({ orderListLoading: true });

      actions.reprocess({
        start_at,
        end_at,
        order,
      });

      // if (orderData.orderErrors) {
      //   setErrorHandlerData({
      //     error: {
      //       ...orderData.orderErrors,
      //       resolveFunction: () => reprocess({ start_at, end_at, order }),
      //     },
      //   });
      //   toast.warn('Houve um erro, por favor verifique as datas escolhidas.');
      // } else {
      toast.success(
        'O processamento está sendo realizado e dentro de instantes a base de dados será atualizada'
      );
      // }

      const orderData = await actions.index({
        search: '',
        order_by: 'id',
        order: 'desc',
      });

      setOrderData({
        ...orderData,
        orderListLoading: false,
      });

      if (orderData.orderErrors) return false;
      return true;
    },
    [setOrderData, setErrorHandlerData]
  );

  return (
    <OrderContext.Provider
      value={{
        ...data,
        setOrderData,
        index,
        indexError,
        show,
        destroy,
        reprocess,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
}

export function useOrder() {
  const context = useContext(OrderContext);

  if (!context) throw new Error('useUser must be used within an UserProvider');

  return context;
}

OrderProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
